@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;300;400;500;700;800&display=swap");

@import "./theme.scss";
body {
  margin-top: 10vh;
  font-family: "M PLUS 1p", sans-serif;
  .navbar-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 88vw;

    background-color: $color--black;
    color: $color--white;
    display: grid;
    padding: 0 5em 0em 5em;
    grid-template-columns: 2fr 3fr 2fr;
    height: 10vh;

    animation: fadeInDown 1s;

    .navbar-logo {
      max-height: 100%;

      img {
        max-height: 80%;
        max-width: 40%;
      }
      .btn-menu {
        display: none;
      }
    }
    .navbar-items {
      display: flex;
      flex-direction: row;

      width: 100%;

      .navbar-item-active {
        color: $color--cyan-bluish-gray;
      }
      a {
        color: white !important;
        text-decoration: none;
        cursor: pointer;

        width: 100%;
        height: 100%;
      }
      a:hover {
        color: $color--cyan-bluish-gray;
      }
    }
    .navbar-social-links {
      width: 60%;
      display: flex;
      justify-content: center;
      a {
        color: $color--white;
        font-size: 1.6em;
        cursor: pointer;
      }
      a:hover {
        color: $color--cyan-bluish-gray;
      }
    }
    * {
      margin: auto;
    }

    .close-menu-background {
      display: none;
    }
  }

  .footer-container {
    height: 10vh;
    background-color: $color--light-gray;
    box-shadow: $shadow--footer;
    padding: 0 10em 0 10em;
    display: grid;
    grid-template-columns: 3fr 1fr 3fr;

    .button-go-up {
      margin-top: -4em;
      background-color: $color--light-gray;
      border-radius: 100%;
      border: none;
      cursor: pointer;
      height: 7em;
      width: 7em;
      .icon-go-up {
        margin: auto;
        color: $color--dark-gray;
        width: 95%;
        height: 95%;
      }
    }
    .footer-items {
      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      * {
        text-decoration: none;
        color: $color--dark-gray;
        cursor: pointer;
      }
      *:hover {
        color: $color--very-dark-gray;
      }
    }
    * {
      margin: auto;
    }
  }
  .home-container {
    min-height: 100vh;

    .landing-container {
      text-decoration: none;

      width: 90vw;

      overflow: hidden;
      cursor: pointer;
      position: relative;
      display: block;
      padding: 0;

      margin: auto;

      .landing-image-mobile {
        display: none;
      }
      .jobs-container {
        height: 80vh;
        width: 85vw;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: absolute;
        z-index: 100;

        .job-container {
          width: 50%;
          text-align: justify;
          margin: auto;
          color: $color--black;
          position: relative;
          h1 {
            font-size: 4.8em;
            margin: 0;
            line-height: 1em;
            font-weight: 800;
          }
          p {
            font-size: 1.03em;
            color: $color--dark-gray;
          }
        }
        .div-coder {
          margin-left: 0;
        }
        .div-teacher {
          margin-right: 0;
          position: relative;
        }
      }
      .image-container {
        position: absolute;
        z-index: 1;
        bottom: 0;
        width: 50vw;
        height: 100%;
        min-height: 30vh;
        margin: 0;
        left: -25vw;
        overflow: hidden;

        .div-cover {
          margin: 0;
          position: relative;
          display: block;
          width: 100%;
          height: 100%;

          .div-img {
            margin: 0;

            img {
              max-height: 100vh;
              max-width: 50vw;
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
    .latest-work-container {
      padding: 5vw;
      background-color: #fafafa;
      width: 90vw;

      .title-container {
        border-bottom: solid 1px #bbb;

        padding-bottom: -3em;
        width: 80vw;
        margin: auto;
        margin-bottom: 3em;
        height: 1em;

        .title {
          margin: auto;
          margin-top: -1.2em;
          background-color: #fafafa;

          color: $color--dark-gray;
          width: fit-content;
          padding-left: 1em;
          padding-right: 1em;
          z-index: 970 !important;
          font-size: 1.2em;
          font-weight: 700;
        }
      }

      .projects-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 80vw;
        margin: 0;
        margin: auto;
      }

      .it-course-cards-container {
        .project-card-title {
          color: #f7a028;
          font-weight: 600;
        }

        .project-card-subtitle {
          color: #d78008;
        }
      }
    }

    .popular-it-lessons-container {
      background-color: #f7a028;

      .title-container {
        border-bottom: solid 1px #fff !important;
        background-color: transparent;
      }

      .title {
        margin: auto;
        margin-top: -1.2em;
        background-color: #f7a028 !important;
        color: white !important;
      }
    }
  }

  .about-container {
    margin-top: -10vh;
    background-color: #f5f5f5;
    .about-me-container {
      width: 80vw;
      margin: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 15vh;
      .about-me-text {
        color: $color--dark-gray;
        text-align: left;

        h1 {
          font-size: 6em;
          font-weight: 900;
          color: $color--very-dark-gray;
          text-align: left;
          letter-spacing: -0.06em;

          margin: auto;
          margin-top: 1em;
        }
        h2 {
          font-size: 1.6em;
          color: $color--dark-gray;
          font-weight: 400;
          margin: 0;
          text-align: left;
        }
        p {
          color: $color--black;
          font-size: $font-size-p;
          font-weight: 300;
          line-height: 2em;
        }

        .what-i-do {
          font-size: 1.2em;
          color: #050505;
          font-weight: 300;
        }
      }
      .about-me-image {
        height: 60vh;

        width: 100%;
        margin: 10vh 5vh;
        // overflow: hidden;

        display: flex;
        justify-content: center;
        img {
          height: 60vh;
          margin: auto;
          margin-top: 10vh;
        }
      }

      .img-what-i-do {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 35vw;
        height: 30vw;
        margin-top: 10vh;
        margin-right: 5vw;
        rotate: -10deg;
        animation: rotate-img 0.5s 0.5s;
        img {
          max-height: unset !important;
          height: 100%;
          width: auto;
          margin: auto;
        }
      }
      @keyframes rotate-img {
        from {
          rotate: -120deg;
        }
      }
    }
    .about-me-images {
      width: 100%;

      .img-grid {
        margin: auto;
        width: 80%;
        height: 8em;
        display: flex;
        justify-content: space-between;

        padding-bottom: 5em;
        img {
          cursor: pointer;
          height: 7.5em;
          transition: height 0.25s;
          transition: box-shadow 0.25s;
          box-shadow: 0em 0em 10px $color--dark-gray;
          border-radius: 5px;
          border: solid $color--white 3px;
        }
        img:hover {
          height: 7.55em;
          box-shadow: 0em 0em 12px $color--very-dark-gray;
        }
      }
    }
  }

  .philosophy-container {
    height: 50vh;
    margin: 2em 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    color: #082134;
    margin-bottom: 0;

    * {
      margin: 0 auto;
    }
    p {
      font-weight: 300;
      font-size: 1.3em;
    }
    h2 {
      font-size: 3em;
      font-weight: 200;
    }
  }

  .count-figures-container {
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    color: white;

    .count-figures-grid {
      padding: 5vh 5vw;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 1em;

      .div-count-figure {
        background-color: #3981b7;
        color: white;
      }
    }
  }

  .random-facts-container {
    width: 80vw;
    margin: auto;
    margin-top: 10vh;
    margin-bottom: 10vh;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .random-facts-text {
      text-align: left;
      margin-left: 2em;

      h3 {
        margin-left: 1.2em;
        font-weight: 400;
        font-size: 2em;
        color: $color--very-dark-gray;
      }
      ul {
        margin-left: 0;
        list-style: none;
        // text-align: left;
        font-size: $font-size-p;
        line-height: 1.8em;
        color: $color--dark-gray;
      }
    }
  }
  .skills-container {
    background-color: #fafafa;

    width: 100%;
    margin-bottom: 7em;
    padding: 3em;

    .skills-header {
      font-weight: 300;
      font-size: 2.4em;
      text-align: right;
      margin-right: 12%;
    }
    .bar {
      height: 70vh;

      margin: auto;
    }
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    margin-top: -5vh;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    margin-top: 5vh;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
    margin-left: -25vw;
    width: 40vw;
  }

  100% {
    opacity: 1;
    margin-left: 0;
    width: 40vw;
  }
}

@keyframes fadeInFromRight {
  0% {
    opacity: 0;
    margin-left: 25vw;
    width: 40vw;
  }

  100% {
    opacity: 1;
    margin-left: 0;
    width: 40vw;
  }
}

.contact-me-page {
  min-height: 90vh;

  .social-media-container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .contact-me-text {
      width: 80%;
      min-height: 60vh;

      margin-left: 20%;
      h1 {
        font-size: 6em;
        font-weight: 900;
        color: $color--very-dark-gray;
        text-align: left;
        letter-spacing: -0.06em;

        margin: auto;
        margin-top: 1em;
      }
      h3 {
        font-size: 1.6em;
        color: $color--dark-gray;
        font-weight: 400;
        text-align: left;

        width: 90%;
      }

      .social-links-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.2em;
        width: 90%;
        margin: 3em;
        margin-left: 0;
        .social-link {
          cursor: pointer;
          display: grid;
          grid-template-columns: 1fr 3fr;
          gap: 0.9em;
          text-decoration: none;
          .social-logo-container {
            border-radius: 100%;
            height: 2.6em;
            width: 2.6em;

            display: flex;

            * {
              margin: auto;
              font-size: 1.8em;

              color: $color--white;
            }
          }
          .social-logo-linkedin {
            background-color: $color--linkedin-blue;
          }
          .social-logo-instagram {
            background-color: $color--instagram-red;
          }
          .social-logo-facebook {
            background-color: $color--facebook-blue;
          }
          .social-logo-line {
            background-color: $color--line-green;
          }
          p {
            font-size: 1.1em;
            font-weight: 700;
            text-align: left;
            width: 100%;
            margin: auto;
          }
          .social-name-linkedin {
            color: $color--linkedin-blue;
          }

          .social-name-instagram {
            color: $color--instagram-red;
          }
          .social-name-facebook {
            color: $color--facebook-blue;
          }
          .social-name-line {
            color: $color--line-green;
          }
        }
        .social-link:hover {
          background-color: $color--light-gray;
          padding: 5px;
          padding-right: 7px;
          margin: -5px;
        }
      }
    }

    .contact-me-photo {
      margin: auto;
    }
  }

  .contact-via-email-container {
    display: grid;
    background-color: $color--light-gray;
    width: 80vw;
    margin: 0;
    padding: 6vh 10vw;
    padding-bottom: 15vw;
    h2 {
      text-align: left;
      font-size: 2em;
      font-weight: 400;
      color: $color--very-dark-gray;
      margin: 2em 0;
    }
    .form-send-email {
      margin: 0;
      .form-input {
        display: grid;

        width: 80vw;
        grid-template-columns: 1fr 1fr;
        gap: 2em;
        .row-1,
        .row-2 {
          display: grid;
          text-align: left;
          gap: 0.5em;
          input,
          textarea {
            min-height: 3.2em;
            border: solid $color--cyan-bluish-gray 1px;
            border-radius: 0.4em;
            font-family: "M PLUS 1p", sans-serif;
            font-size: 1em;
            line-height: 0.7em;
            padding-left: 1em;
          }
          textarea {
            line-height: 1.2em;
            padding: 0.5em;
          }
        }
      }
    }
    .send-email-button {
      cursor: pointer;
      width: 12vw;
      margin: 2em;
      height: 3.2em;
      margin-left: 68vw;
      background-color: $color--very-dark-gray;
      color: $color--white;
      border: none;
      font-size: 1em;
      font-weight: 900;
      border-radius: 0.2em;
    }
  }
}

.portfolio-container {
  min-height: 90vh;
  width: 100vw;

  margin: auto;

  .portfolio-main-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 2em;
    padding-top: 5em;
    width: 80%;
    margin: auto;
    .div-left {
      width: 40vw;
      h1 {
        font-size: 6em;
        font-weight: 900;
        color: $color--very-dark-gray;
        text-align: left;
        letter-spacing: -0.06em;

        margin: auto;
      }
      h3 {
        font-size: 1.6em;
        color: $color--dark-gray;
        font-weight: 400;
        margin: 0;
        text-align: left;
      }
      p {
        text-align: left;
        color: $color--black;
        font-size: $font-size-p;
        font-weight: 400;
        line-height: 2em;
      }
    }
    .div-right {
      .img {
        margin: auto;
        width: 50vw;
        max-height: 70vh;
        overflow: hidden;

        img {
          min-width: 40vw;
          min-height: 70%;
          width: 40vw;
          margin: auto;
          overflow: hidden;
        }
      }
    }
  }

  .projects-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 80vw;
    margin: 0;
    padding: 10vw;
    background-color: #fafafa;
  }
}
.div-english-courses,
.div-it-courses {
  min-height: fit-content;
  min-width: fit-content;
  width: 100vw !important;
  height: 60vh !important;
  position: absolute;
  top: -20vh;
  left: 0;
  padding: 0;
  display: flex;
}

.div-courses-container {
  width: 80%;
  height: 100%;
  margin: 0;
  position: relative;
}

.div-course {
  position: absolute;
  font-size: 1.2em;
  opacity: 0.7;
  h3 {
    font-weight: 500;
  }
}

.div-course-1 {
  top: 20%;
  rotate: -6deg;
  left: 40%;
  color: #050505;

  animation: div-course-1 0.5s;
}
@keyframes div-course-1 {
  from {
    left: 0%;
    rotate: -150deg;
    font-size: 0.8em;
    top: 0;
    opacity: 0;
  }
}

.div-course-2 {
  top: 10%;
  rotate: 0deg;
  left: 15%;
  color: #550055;

  animation: div-course-2 0.5s;
}

@keyframes div-course-2 {
  from {
    left: 20%;
    rotate: 180deg;
    font-size: 0.8em;
    opacity: 0;
  }
}

.div-course-3 {
  top: 40vh;
  rotate: 15deg;
  right: 30%;
  animation: div-course-3 0.5s;

  color: #005500;
  width: fit-content !important;

  text-wrap: nowrap;
}

@keyframes div-course-3 {
  from {
    right: 50%;
    rotate: -120deg;
    font-size: 0.8em;
    opacity: 0;
  }
  to {
  }
}

.div-course-4 {
  top: 90%;
  rotate: 0;
  left: 35%;
  color: #ff5555;
  animation: div-course-4 0.5s;
  word-wrap: break-word;
  max-width: 20vw;
}

@keyframes div-course-4 {
  from {
    left: 0%;
    rotate: -210deg;
    font-size: 0.8em;
    opacity: 0;
  }
}

.div-course-5 {
  top: 40%;
  rotate: -14deg;
  left: 10%;
  max-width: 25vw;
  color: #00367d !important;
  animation: div-course-5 0.5s;
}

@keyframes div-course-5 {
  from {
    left: -20%;
    rotate: -190deg;
    font-size: 0.8em;
    opacity: 0;
  }
  to {
  }
}

.div-icons-container {
  width: 80%;
  height: 100%;
  margin: 0;
  position: relative;
}

.div-course-icon {
  position: absolute;
  opacity: 0.4;
  img {
    max-height: 2em;

    position: relative;
  }
}

.div-course-icon-1 {
  left: 40%;
  rotate: 30deg;
  top: 45vh;
  animation: div-icon-1 0.75s;
}

@keyframes div-icon-1 {
  from {
    left: 70%;
    rotate: -190deg;
    top: 40vh;
    opacity: 0;
  }
}
.div-course-icon-2 {
  left: 50%;
  rotate: -30deg;
  animation: div-icon-2 0.75s;
  img {
    max-height: 7vh;
  }
}

@keyframes div-icon-2 {
  from {
    left: 70%;
    rotate: -190deg;
    top: 20vh;
    opacity: 0;
  }
}
.div-course-icon-3 {
  left: 20%;
  top: 10vh;
  rotate: -30deg;
  animation: div-icon-3 0.75s;
}

@keyframes div-icon-3 {
  from {
    left: 50%;
    rotate: -190deg;
    top: 20vh;
    opacity: 0;
  }
}

.div-course-icon-4 {
  left: 50%;
  top: 30vh;
  rotate: -30deg;
  animation: div-icon-4 0.75s;
}

@keyframes div-icon-4 {
  from {
    left: 70%;
    rotate: -190deg;
    top: 20vh;
    opacity: 0;
  }
}

.div-course-icon-5 {
  left: 20%;
  top: 70vh;
  rotate: -20deg;
  animation: div-icon-5 0.75s;
}

@keyframes div-icon-5 {
  from {
    left: 40%;
    rotate: -190deg;
    top: 60vh;
    opacity: 0;
  }
}

.div-course-icon-6 {
  left: 0%;
  top: 0;
  rotate: -5deg;
  animation: div-icon-6 0.75s;
  img {
    max-height: 4em;
  }
}

@keyframes div-icon-6 {
  from {
    left: 60%;
    rotate: -190deg;
    top: 20vh;
    opacity: 0;
  }
}

.div-course-icon-7 {
  left: 0%;
  top: 60vh;
  rotate: -20deg;
  animation: div-icon-7 0.75s;
}

@keyframes div-icon-7 {
  from {
    left: 10%;
    rotate: -190deg;
    top: 65vh;
    opacity: 0;
  }
}
.div-it-courses {
  left: -300%;
  width: 100vw;
  flex-direction: row-reverse;
}
.div-it-icons-container {
  height: 200%;

  .div-it-course-icon-1 {
    top: 15%;
    left: 45%;
    rotate: 90deg;
    animation: div-it-course-icon-1 0.75s;
  }
  @keyframes div-it-course-icon-1 {
    from {
      top: 25%;
      left: 0%;
      rotate: 290deg;
      opacity: 0;
    }
  }

  .div-it-course-icon-2 {
    top: 1%;
    left: 55%;
    rotate: 9deg;
    animation: div-it-course-icon-2 0.75s;
  }
  @keyframes div-it-course-icon-2 {
    from {
      top: 25%;
      left: 0%;
      rotate: 290deg;
      opacity: 0;
    }
  }

  .div-it-course-icon-3 {
    top: 55%;
    left: 45%;
    rotate: -15deg;
    animation: div-it-course-icon-3 0.75s;
    img {
      min-height: 3em;
      min-width: 3em;
    }
  }
  @keyframes div-it-course-icon-3 {
    from {
      top: 25%;
      left: 0%;
      rotate: 290deg;
      opacity: 0;
    }
  }
  .div-it-course-icon-4 {
    top: 20%;
    left: 5%;
    rotate: 9deg;
    animation: div-it-course-icon-4 0.75s;
  }
  @keyframes div-it-course-icon-4 {
    from {
      top: 35%;
      left: -5%;
      rotate: 290deg;
      opacity: 0;
    }
  }

  .div-it-course-icon-5 {
    top: 40%;
    left: 65%;
    rotate: 20deg;
    animation: div-it-course-icon-5 0.75s;
  }
  @keyframes div-it-course-icon-5 {
    from {
      top: 35%;
      left: 0%;
      rotate: 290deg;
      opacity: 0;
    }
  }
  .div-it-course-icon-6 {
    top: 1%;
    left: 5%;
    rotate: -29deg;
    animation: div-it-course-icon-6 0.75s;
    img {
      min-height: 3em;
      min-width: 3em;
    }
  }
  @keyframes div-it-course-icon-6 {
    from {
      top: 25%;
      left: 0%;
      rotate: 290deg;
      opacity: 0;
    }
  }

  .div-it-course-icon-7 {
    top: 50%;
    left: 25%;
    rotate: 9deg;
    animation: div-it-course-icon-7 0.75s;
    img {
      min-height: 3em;
      min-width: 3em;
      opacity: 0.3;
    }
  }
  @keyframes div-it-course-icon-7 {
    from {
      top: 25%;
      left: 0%;
      rotate: 290deg;
      opacity: 0;
    }
  }

  .div-it-course-icon-8 {
    top: 5%;
    left: 35%;
    rotate: 9deg;
    animation: div-it-course-icon-8 0.75s;
  }
  @keyframes div-it-course-icon-8 {
    from {
      top: 25%;
      left: 0%;
      rotate: 290deg;
      opacity: 0;
    }
  }
  .div-it-course-icon-9 {
    top: 57%;
    left: 75%;
    rotate: 9deg;
    animation: div-it-course-icon-9 0.75s;
  }
  @keyframes div-it-course-icon-9 {
    from {
      top: 25%;
      left: 0%;
      rotate: 290deg;
      opacity: 0;
    }
  }

  .div-it-course-icon-10 {
    top: 55%;
    left: -90%;
    rotate: 0;
    animation: div-it-course-icon-10 0.75s;
    width: fit-content;
    height: fit-content;
    img {
      min-width: 4em;
      min-height: 4em;
    }
  }
  @keyframes div-it-course-icon-10 {
    from {
      top: 25%;
      left: 0%;
      rotate: 290deg;
      opacity: 0;
    }
  }

  .div-it-course-icon-11 {
    top: 15%;
    left: 60%;
    rotate: -2deg;
    animation: div-it-course-icon-11 0.75s;
    img {
      min-width: 5em;
      min-height: 5em;
    }
  }
  @keyframes div-it-course-icon-11 {
    from {
      top: 25%;
      left: 0%;
      rotate: 290deg;
      opacity: 0;
    }
  }

  .div-it-course-icon-12 {
    top: 5%;
    left: -95%;
    rotate: 9deg;
    animation: div-it-course-icon-12 0.75s;
    img {
      min-width: 5em;
      min-height: 5em;
    }
  }
  @keyframes div-it-course-icon-12 {
    from {
      top: 25%;
      left: 0%;
      rotate: 290deg;
      opacity: 0;
    }
  }
}

.div-it-courses-container .div-course {
  width: fit-content;
  h3 {
    font-size: 1.4em;
    font-weight: 900;
  }
}
.div-it-course-1 {
  top: 20%;
  left: 20%;
  rotate: -20deg;
  animation: div-it-course-1 0.75s;
  color: #f7a028 !important;
}
@keyframes div-it-course-1 {
  from {
    top: 30%;
    left: 50%;
    rotate: -200deg;
  }
}
.div-it-course-2 {
  top: 40%;
  left: 30%;
  rotate: -2deg;

  animation: div-it-course-2 0.75s;
  color: #00367d !important;
}
@keyframes div-it-course-2 {
  from {
    top: 50%;
    left: 50%;
    rotate: 100deg;
  }
}

.div-it-course-3 {
  top: 40%;
  left: 0%;
  rotate: 20deg;

  animation: div-it-course-3 0.75s;
  color: #550055 !important;
}
@keyframes div-it-course-3 {
  from {
    top: 50%;
    left: 50%;
    rotate: 100deg;
  }
}

.div-it-course-4 {
  top: 60%;
  left: 0%;
  rotate: -15deg;
  animation: div-it-course-4 0.75s;
  color: #005500 !important;
}
@keyframes div-it-course-4 {
  from {
    top: 50%;
    left: 50%;
    rotate: 100deg;
  }
}

.div-it-course-5 {
  top: 85%;
  left: 0%;
  rotate: 0;
  max-width: 35%;
  line-height: 1.8em;

  animation: div-it-course-5 0.75s;
  color: #050505 !important;
  font-size: 1em !important;
}
@keyframes div-it-course-5 {
  from {
    top: 50%;
    left: 50%;
    rotate: 100deg;
  }
}
.div-my-style-img {
  height: fit-content;
  position: relative;
  margin-top: -20vh;
  min-height: 100%;
}
.div-my-style-img img:nth-child(1) {
  rotate: 20deg;
}
.div-my-style-img img:nth-child(2) {
  rotate: -20deg;
  margin: 0;
  margin-left: -10vw;
}

/* Container styling */
.expandable-button-container {
  position: relative;
  display: inline-block;
  margin: 10px;
  cursor: pointer;
}

/* Link styling for the main button */
.expandable-button-title {
  display: inline-block;
  padding: 5px 5px;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;

  transition: background-color 0.3s ease;
  cursor: pointer;
}

.expandable-button-title:hover {
  background-color: #000;
  cursor: pointer;
}

/* Dropdown container */
.toggle-options-container {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: #000;

  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

/* Individual option styling */
.expandable-button-option {
  padding: 10px;
  white-space: nowrap;
  border-bottom: solid 1px white;
  cursor: pointer;
}

.expandable-button-option a {
  text-decoration: none;
  color: #333;
  font-size: 14px;
  transition: color 0.3s ease;
  cursor: pointer;
}

.expandable-button-option a:hover {
  color: #000;
  cursor: pointer;
}

/* Styling for nested dropdowns */
.expandable-button-container .expandable-button-container {
  position: relative;
  margin: 0;
  cursor: pointer;
}

/* Position nested dropdowns to the right */
.expandable-button-container .toggle-options-container {
  top: 0;
  left: 100%;
  margin-left: 5px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

/* Hover effect for the entire button and dropdown */
.expandable-button-container:hover > .toggle-options-container {
  display: flex;
  cursor: pointer;
}

.not-found-container {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  h2 {
    font-weight: 500;
    font-size: 5em;
    margin: 0 auto;
    color: #555;
  }
  h3 {
    font-weight: 200;
    font-size: 2em;
    margin: 0 auto;
  }
  a {
    background-color: #333;
    width: fit-content;
    margin: 2em auto;
    padding: 0.4em 2em;
    color: white;
    text-decoration: none;
    border-radius: 8px;
  }
}
