$color--black: #000000;
$color--cyan-bluish-gray: #abb8c3;
$color--dark-gray: #757575;
$color--very-dark-gray: #333333;
$color--light-gray: #f5f5f5;
$color--white: #ffffff;
$color--pale-pink: #f78da7;
$color--vivid-red: #cf2e2e;
$color--luminous-vivid-orange: #ff6900;
$color--luminous-vivid-amber: #fcb900;
$color--light-green-cyan: #7bdcb5;
$color--vivid-green-cyan: #00d084;
$color--pale-cyan-blue: #8ed1fc;
$color--vivid-cyan-blue: #0693e3;
$color--vivid-purple: #9b51e0;
$color--youtube-red: #ff0000;
$gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
  135deg,
  rgba(6, 147, 227, 1) 0%,
  rgb(155, 81, 224) 100%
);
$gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
  135deg,
  rgb(122, 220, 180) 0%,
  rgb(0, 208, 130) 100%
);
$gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
  135deg,
  rgba(252, 185, 0, 1) 0%,
  rgba(255, 105, 0, 1) 100%
);
$gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
  135deg,
  rgba(255, 105, 0, 1) 0%,
  rgb(207, 46, 46) 100%
);
$gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
  135deg,
  rgb(238, 238, 238) 0%,
  rgb(169, 184, 195) 100%
);
$gradient--cool-to-warm-spectrum: linear-gradient(
  135deg,
  rgb(74, 234, 220) 0%,
  rgb(151, 120, 209) 20%,
  rgb(207, 42, 186) 40%,
  rgb(238, 44, 130) 60%,
  rgb(251, 105, 98) 80%,
  rgb(254, 248, 76) 100%
);
$gradient--blush-light-purple: linear-gradient(
  135deg,
  rgb(255, 206, 236) 0%,
  rgb(152, 150, 240) 100%
);
$gradient--blush-bordeaux: linear-gradient(
  135deg,
  rgb(254, 205, 165) 0%,
  rgb(254, 45, 45) 50%,
  rgb(107, 0, 62) 100%
);
$gradient--luminous-dusk: linear-gradient(
  135deg,
  rgb(255, 203, 112) 0%,
  rgb(199, 81, 192) 50%,
  rgb(65, 88, 208) 100%
);
$gradient--pale-ocean: linear-gradient(
  135deg,
  rgb(255, 245, 203) 0%,
  rgb(182, 227, 212) 50%,
  rgb(51, 167, 181) 100%
);
$gradient--electric-grass: linear-gradient(
  135deg,
  rgb(202, 248, 128) 0%,
  rgb(113, 206, 126) 100%
);
$gradient--midnight: linear-gradient(
  135deg,
  rgb(2, 3, 129) 0%,
  rgb(40, 116, 252) 100%
);
$duotone--dark-grayscale: url(#wp-duotone-dark-grayscale);
$duotone--grayscale: url(#wp-duotone-grayscale);
$duotone--purple-yellow: url(#wp-duotone-purple-yellow);
$duotone--blue-red: url(#wp-duotone-blue-red);
$duotone--midnight: url(#wp-duotone-midnight);
$duotone--magenta-yellow: url(#wp-duotone-magenta-yellow);
$duotone--purple-green: url(#wp-duotone-purple-green);
$duotone--blue-orange: url(#wp-duotone-blue-orange);
$font-size--small: 13px;
$font-size--medium: 20px;
$font-size--large: 36px;
$font-size--x-large: 42px;
$spacing--20: 0.44rem;
$spacing--30: 0.67rem;
$spacing--40: 1rem;
$spacing--50: 1.5rem;
$spacing--60: 2.25rem;
$spacing--70: 3.38rem;
$spacing--80: 5.06rem;
$font-size-p: 1.2em;
$shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
$shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
$shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
$shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1),
  6px 6px rgba(0, 0, 0, 1);
$shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
$shadow--footer: inset 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
$color--linkedin-blue: #0982c0;
$color--instagram-red: #ef0074;
$color--facebook-blue: #43609c;
$color--line-green: #00b900;
