@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;300;400;500;700;800&family=Sour+Gummy:ital,wght@0,100..900;1,100..900&display=swap");

.random-background-1 {
  background: linear-gradient(135deg, #ff7eb3, #ff758c);
}

.random-background-2 {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
}

.random-background-3 {
  background: linear-gradient(135deg, #ff9a9e, #fad0c4);
}

.random-background-4 {
  background: linear-gradient(135deg, #ff6a00, #ee0979);
}

.random-background-5 {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
}

.random-background-6 {
  background: linear-gradient(135deg, #f77062, #fe5196);
}

.random-background-7 {
  background: linear-gradient(135deg, #11998e, #38ef7d);
}

.random-background-8 {
  background: linear-gradient(135deg, #8a2be2, #4b0082);
}

.random-background-9 {
  background: linear-gradient(135deg, #e55d87, #5fc3e4);
}

.random-background-10 {
  background: linear-gradient(135deg, #00b09b, #96c93d);
}

.random-background-11 {
  background: linear-gradient(135deg, #ff512f, #f09819);
}

.random-background-12 {
  background: linear-gradient(135deg, #56ccf2, #2f80ed);
}

.random-background-13 {
  background: linear-gradient(135deg, #a18cd1, #fbc2eb);
}

.random-background-14 {
  background: linear-gradient(135deg, #1f4037, #99f2c8);
}

.random-background-15 {
  background: linear-gradient(135deg, #b24592, #f15f79);
}

.random-background-16 {
  background: linear-gradient(135deg, #ff0844, #ffb199);
}

.random-background-17 {
  background: linear-gradient(135deg, #d3cce3, #e9e4f0);
}

.random-background-18 {
  background: linear-gradient(135deg, #43cea2, #185a9d);
}

.random-background-19 {
  background: linear-gradient(135deg, #ff5f6d, #ffc371);
}

.random-background-20 {
  background: linear-gradient(135deg, #ed4264, #ffedbc);
}

.wordle-container {
  width: 100vw;
  text-align: center;
  font-size: 1.2rem;
  margin: auto;
  min-height: 80vh;
  margin-bottom: 10vh;
  gap: 1em;
}

.wordle-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}
.wordle-buttons {
  padding: 0;
  display: flex;
  justify-content: center;
}

.wordle-button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #f0f0f0;
  padding: 10px 15px;
  margin: 10px;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.2s ease, transform 0.1s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wordle-button:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
}

.wordle-button:active {
  transform: scale(0.98);
}

.wordle-button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.btn-center {
  margin: auto;
}
.wordle-setting-buttons {
  display: flex;
  justify-content: space-between;

  margin: 1em auto;
}
.wordle-settings-button {
  background-color: #4caf50;
  color: white;
  margin: auto;
}
.wordle-settings-button:hover {
  background-color: #3c9f40;
}

.wordle-reset-button {
  background-color: white;
  color: #414a5e;
  border: solid 1px #414a5e;
}
.wordle-reset-button:hover {
  background-color: white;
}
/* Word grid styling */
.wordle-word-grid {
  display: grid;

  margin-bottom: 30px;
  width: fit-content;
  height: 360px;
  margin: auto;
  padding-bottom: 20px;
}
.wordle-word-grid {
  display: grid;
  gap: 10px; /* Spacing between tiles */
}

.wordle-tile {
  width: 40px;
  height: 40px;
  margin: auto;

  border: 2px solid #ddd !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.wordle-tile.space {
  background-color: transparent !important; /* Invisible tile for spacing */
  border: none !important; /* Remove border */
  pointer-events: none !important; /* Non-interactive */
}

.wordle-tile.hidden {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.3s, transform 0.3s;
}

.wordle-tile.revealed {
  opacity: 1;
  transform: scale(1);
}

.wordle-tile.green {
  background-color: #6aaa64;
  color: #fff;
}

.wordle-tile.yellow {
  background-color: #f3c237;
  color: #fff;
}

.wordle-tile.gray {
  background-color: #787c7e;
  color: #fff;
}

.wordle-tile.white {
  background-color: #fff;
  color: #333;
}

.wordle-tile.correct {
  background-color: #6aaa64;
  color: #fff;
}

.wordle-tile.present {
  background-color: #f3c237 !important;
  color: #fff;
}

.wordle-tile.absent {
  background-color: #787c7e;
  color: #fff;
}

.wordle-hint-closer,
.challenge-form-closer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}
/* Definition and feedback modal */
.wordle-modal {
  display: none;
  justify-content: center;
  align-items: center;

  position: relative;
  z-index: 40000000;
  display: flex;
}

.wordle-modal.open {
  display: flex;
  position: absolute;
  top: 15vh;
  width: 100vw;
}

.wordle-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  text-align: left;
  z-index: 4;
  max-height: 75vh;
  overflow-y: scroll;
}

.wordle-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.wordle-modal-title {
  font-size: 1.8rem;
}

.wordle-modal-close {
  background-color: #ff4d4d;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #fff;
  font-size: 1.5rem;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.wordle-modal-close:hover {
  background-color: #ff1a1a;
}

.wordle-modal-body {
  font-size: 1rem;
  margin-bottom: 15px;
}

.wordle-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Timer */
.wordle-timer {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.wordle-timer span {
  font-weight: bold;
}

/* Confetti and win messages */
.wordle-win-message {
  font-size: 2rem;
  color: #4caf50;
  margin-top: 20px;
  display: none;
}

.wordle-win-message.show {
  display: block;
}

.wordle-confetti {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

.wordle-confetti.show {
  display: block;
  animation: wordle-confetti-fall 3s ease-in-out forwards;
}

@keyframes wordle-confetti-fall {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

/* Feedback message */
.wordle-feedback-message {
  position: absolute;
  font-size: 1.2rem;

  color: #333;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.feedback-bg {
  background-color: white;
  width: fit-content;
  padding: 10vh 10vw;
  margin: auto;
  z-index: 2;
}
.closer-div {
  background-color: #414a5e55;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}
.wordle-feedback-message.feedback-bg.correct {
  color: #6aaa64;
}

.wordle-feedback-message.feedback-bg.present {
  color: #f3c237 !important;
}

.wordle-feedback-message .feedback-bg p .absent {
  color: red;
  font-weight: bolder;
}

.wordle-input-area {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 20px;

  width: 500px;
  height: 200px;
  margin: auto;
}

.wordle-keyboard-row {
  display: flex;
  justify-content: baseline;
  flex-grow: 1;
  margin: 5px;
  height: 100%;
  max-height: 45px;
}

.wordle-input-button {
  width: 40px;
  height: 100%;
  margin: 2px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  background-color: #ddd;
  cursor: pointer;
  transition: background-color 0.3s;
  flex-grow: 1;
  font-weight: 1000;
  background-color: #dce1ed;
  color: #414a5e;
}

.wordle-input-button:hover {
  background-color: #aaa;
}

.wordle-input-button:active {
  background-color: #888;
}

.wordle-input-button.enter {
  width: 80px; /* Wider button for Enter */
}

.wordle-input-button.backspace {
  width: 80px; /* Wider button for Backspace */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wordle-input-button.backspace p {
  margin: auto;

  line-height: 0;
}

.wordle-input-button:active {
  transform: scale(0.98);
}

.wordle-input-button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.wordle-input-button.key-green {
  background-color: #6aaa64; /* Green for correct */
  color: white;
}

.wordle-input-button.key-yellow {
  background-color: #f3c237 !important; /* Yellow for misplaced */
  color: white;
}

.wordle-input-button.key-gray {
  background-color: #787c7e; /* Gray for incorrect */
  color: white;
}

/* Leaderboard */
.wordle-leaderboard {
  margin-top: 30px;
}

.wordle-leaderboard ul {
  list-style-type: none;
  padding: 0;
}

.wordle-leaderboard li {
  font-size: 1.1rem;
  padding: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.wordle-leaderboard li span {
  font-weight: bold;
}

.wordle-leaderboard .leader {
  font-size: 1.3rem;
  font-weight: bold;
  color: #4caf50;
}

/* Hints button */
.wordle-hint-button {
  background-color: #ffeb3b;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.2s ease;
}

.wordle-hint-button:hover {
  background-color: #fbc02d;
}
.wordle-settings-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  overflow-y: scroll;
}
.wordle-settings-closer {
  background-color: #414a5e55;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
.wordle-settings-main {
  background-color: white;
  z-index: 3;
  position: relative;
  width: 400px;
  height: fit-content;
  margin: auto;
  margin-top: 10vh;
  padding: 5vh 3vw;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #333;
}

.dropdown-label {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 35vh;
  overflow-y: scroll;
}

.checkbox-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
}
.check-box-option-text p {
  text-align: left;

  font-weight: 400 !important;
}
.check-box-option-text {
  text-align: left;
}
.check-box-option-text-helper {
  display: inline;
}
.check-box-option-text-helper p {
  font-weight: 200 !important;
  font-style: italic;
  font-size: 0.8em;
}
.wordle-dropdown-grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.wordle-settings-dropdown {
  width: 100%;
  height: 40px;
  border: solid 1px #414a5e55;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: end;
}
.wordle-settings-dropdown svg {
  margin: auto 0.5em;
}

.checkbox-item input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.selected-items {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #ddd;
}

.selected-items ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.selected-items li {
  margin-bottom: 0.3rem;
}
@media only screen and (max-width: 768px) {
  .wordle-container {
    width: 100%;
    padding: 0;
    font-size: 1rem;
  }

  .wordle-title {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .wordle-word-grid {
    gap: 5px;
  }

  .wordle-tile {
    width: 30px;
    height: 30px;
    font-size: 1rem;
  }

  .wordle-input-area {
    width: 100%;
    height: auto;
    margin-top: 10px;
    overflow: hidden;
  }

  .wordle-keyboard-row {
    margin: 2px;
    height: auto;
    min-height: 50px;
    padding: 0;
    width: fit-content;
    min-width: 85%;
    max-width: 100%;
    margin: 0.35em auto;

    display: flex;
    justify-content: stretch;
  }

  .wordle-input-button {
    width: fit-content;
    max-width: 35px;
    height: 45px;
    font-size: 14px;
  }

  .wordle-input-button.enter,
  .wordle-input-button.backspace {
    max-width: 50px;
    width: fit-content;
    overflow: hidden;
  }

  .wordle-modal-content {
    max-width: 80%;
    padding: 15px;
    margin: auto;
    height: fit-content;
    overflow-y: scroll;
  }

  .wordle-settings-main {
    width: 90%;
    margin-top: 5vh;
    padding: 4vh 2vw;
  }

  .wordle-dropdown-grid {
    grid-template-columns: 1fr 2fr;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .wordle-container {
    padding: 0 20px;
    font-size: 1.1rem;
  }

  .wordle-title {
    font-size: 2.2rem;
  }

  .wordle-word-grid {
    gap: 8px;
  }

  .wordle-tile {
    width: 35px;
    height: 35px;
    font-size: 1.2rem;
  }

  .wordle-input-area {
    width: 100%;
    height: auto;
  }

  .wordle-keyboard-row {
    margin: 4px;
    height: auto;
  }

  .wordle-input-button {
    width: 40px;
    font-size: 16px;
  }

  .wordle-input-button.enter,
  .wordle-input-button.backspace {
    width: 70px;
  }

  .wordle-modal-content {
    max-width: 80%;
    padding: 20px;
  }

  .wordle-settings-main {
    width: 80%;
    margin-top: 7vh;
    padding: 5vh 3vw;
  }

  .wordle-dropdown-grid {
    grid-template-columns: 1fr 3fr;
  }
}
.pexels-container {
  display: flex;
  justify-content: center;
}
.pexels-image-div img {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 35vh;
  max-width: 100%;
  height: auto;
  width: auto;
  overflow: hidden;
  margin: auto;
}
.p-thanks-pexels {
  max-height: 100%;
  font-weight: 200;
}

.div-name-input {
  min-width: 400px;
  margin: 0;
  height: 5vh;
  display: flex;
  border: solid 1px #cccc;
  border-radius: 5px;
}
.div-name-input input {
  width: 100%;
  border: none;
}
.div-name-input button {
  cursor: pointer;
  border: none;
}
.point-deduction {
  color: red;

  font-size: 0.8em;
  min-width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: -0 3em;
  text-align: left;
  margin-bottom: 1em;
  animation: fade-in 0.5s;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
}
.point-deduction p {
  line-height: 0;
}
.leaderboard-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 90vh;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.leaderboard-closer {
  background-color: #0005;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
.leaderboard-modal-container {
  background-color: white;
  position: relative;
  z-index: 1;
  width: 40vw;
  margin: auto;
  padding: 1em 2em;
  font-size: 1em !important;
  line-height: 1em;
}
.leaderboard-list-container {
  max-height: 60vh;
  overflow-y: scroll;
}
.leaderboard-table-container {
  border: solid 1px #cccc;
  display: grid;
  grid-template-columns: 1fr 4fr 2fr 2fr;
  padding: 0;
}
.leaderboard-table-container-header {
  background-color: #3333;
}

.leaderboard-table-container-header p {
  line-height: 0;
}

.leaderboard-table-container-body {
  padding: 0;
  max-height: fit-content;
}
.leaderboard-table-container-body p {
  font-weight: 300;
  line-height: 0;
}
.leaderboard-table-container-own {
  background-color: #3c9f40;
  color: white;
}
.leaderboard-table-container-own p {
  font-weight: 600;
}
.leaderboard-header-container {
  display: grid;
  grid-template-columns: 5fr 1fr 1fr;
  margin-bottom: 5vh;
}
.leaderboard-header-container h4 {
  text-align: left;
  margin: auto 0;
}
.leaderboard-header-container select {
  max-height: 7vh;
  padding: 1vh;
  margin: 0 0.25vh;
  cursor: pointer;
}
.thai-words-container {
  display: block;
}
.word-info {
  font-size: 0.9em;
  font-weight: 200;
}

.div-view-more {
  height: fit-content;
  max-height: 6vh;

  display: flex;
  justify-content: baseline;
  gap: 1em;
  cursor: pointer;

  border-bottom: solid 1px #3c9f40;
  margin-bottom: 1em;
}
.div-view-more * {
  margin: auto 0;
  color: #414a5e;
  fill: #414a5e;
}
.div-view-more button {
  height: fit-content;
  width: fit-content;
  padding: 0;
}
.div-more-meaings {
}
.game-win-buttons {
  display: flex;
  justify-content: space-around;
}
.game-win-buttons button {
  background-color: rgb(6, 147, 227);
  color: white;

  cursor: pointer;
  border: none;
  outline: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.2s ease, transform 0.1s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.btn-share {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  width: fit-content;
}
.btn-share * {
  margin: auto;
}

.passwords-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  gap: 0.2em;
  max-width: 30vw;
  width: 60%;
  margin: 2vh auto;
}
.passwords-container button {
  background-image: linear-gradient(
    to bottom right,
    rgba(200, 0, 0, 0.9),
    rgba(200, 0, 0, 0.6)
  ); /* Two shades of red */
  width: 100%;
  aspect-ratio: 1;
  font-size: 1em;
  padding: 0;
  /* Remove previous background color if needed */
  background-color: transparent;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.btn-password-active {
  background-image: linear-gradient(
    to bottom right,
    rgba(0, 200, 0, 0.9),
    rgba(0, 200, 0, 0.6)
  ) !important;
}
.passwords-container button svg {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  fill: white;
}

.wordle-log-in-container {
  position: fixed;
  width: 40vw;
  height: fit-content;
  min-height: 40vh;
  top: 20vh;
  left: 25vw;
  padding: 5vh 5vw;
  background-color: white;
  z-index: 3;
}
.wordle-log-in-closer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
}
.wordle-login-button {
  background-image: linear-gradient(
    to bottom right,
    rgba(0, 200, 0, 0.9),
    rgba(0, 200, 0, 0.6)
  ) !important;
  color: white;
  border: none;
  width: 60%;
  padding: 1vh 0;
  font-size: 1.2em;
  border-radius: 6px;
}

.challenge-form-container {
  background-color: white;
  position: absolute;
  top: 20vh;
  width: 50%;
  height: 60vh;
  overflow-y: scroll;
  left: 20%;
  padding: 2% 5%;
  z-index: 4;
}
.challenge-grid {
  grid-template-columns: 1fr;
  color: #414a5e;
}
.checkbox-group-challenge {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.challenge-options {
  display: flex;
  justify-content: space-around;
  gap: 1em;
}
.challenge-options button {
  background-color: white;
  border: solid 1px #414a5e;
  border-radius: 4px;
  padding: 4px;
  width: 100%;
  cursor: pointer;
}
.challenge-options .selected {
  background-color: #414a5e;
  color: white;
}
.create-wordle-challenge-buttons {
  margin-top: 5vh;
  display: flex;
  justify-content: space-around;
}
.create-wordle-challenge-button {
  background-color: #6aaa64;
  font-weight: 500;
  color: white;
  cursor: pointer;
}
.create-wordle-challenge-button:hover {
  background-color: #3c9f40 !important;
}

.container-header-challenge {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  max-height: 5vh;
  padding: 0 2em;
}
.container-header-challenge * {
  margin: auto 0;
}

.div-challenges-lists {
  border: solid 1px #414a5e22;
  margin-top: 1em;
  height: 80%;
  max-height: 80%;
  overflow-y: scroll;
}

.div-challenge-table-item {
  width: 95%;
  margin: 1em auto;
  border: solid 2px #1f4037;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  height: fit-content;

  /* font-weight: 900 !important; */
}
.challenge-title {
  margin: 0;
  padding: 5px 0;
  height: fit-content;
  border-radius: 8px 8px 0 0;
}
.challenge-name {
  text-align: left;
  color: white;
  margin-left: 1em;
  display: flex;
  justify-content: baseline;
  gap: 1em;
}
.challenge-name h3 {
  margin: 0;
  font-family: "Sour Gummy", serif !important;
  font-weight: 900;
}

.challenge-body {
  text-align: left;
  padding: 0 1em;
  display: grid;
  grid-template-columns: 5fr 1fr;
}
.challenge-go-button {
  display: flex;
}

.div-intro-container {
  width: 50%;
  margin: auto;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.div-intro-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0;
  padding: 3vh;
}
.div-intro-head sub {
  font-weight: 200;
}
.div-intro-head h2 {
  margin: 0;
  font-size: 2em;
  font-weight: 100;
}

.div-intro-body {
  text-align: left;

  height: fit-content;
}
.div-intro-instructions p {
  font-weight: 300;
}
.div-intro-message {
  font-size: 1.6em;
  text-align: center;
  font-weight: 200 !important;
}

.wordle-start-challenge {
  width: fit-content;
  max-width: none;
  padding: 12px 36px;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  gap: 0 !important;
  margin-bottom: 5vh;
}
.wordle-start-challenge * {
  margin: auto 5px;
}

.bunny-race-container {
  width: 100%;
  height: 40vh;
}
.track-container {
  position: relative !important;
  height: 100%;

  display: flex;
}
.img-track {
  max-height: 40vh;
  min-width: 100%;
  width: auto;
}

.player-names-container {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;

  display: grid;
  grid-template-rows: repeat(5, 1fr);
  z-index: 1;
  padding: 0 0.25em;
}
.div-player-name {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.div-player-name-header {
  color: white;
  padding: 2px 10px;
  width: fit-content;
  border-radius: 4px;
  max-width: 60%;
  overflow: hidden;
}
.div-rank {
  position: absolute;
  left: 80vh;
  height: fit-content !important;

  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-top: -2vh;
}
.div-rank-header {
  text-align: center !important;
  padding: 0 1em;
  width: fit-content;
}
.player-names-container h3 {
  font-size: 0.6em;
  margin: auto 0;
  text-align: left;
}
.rabbit-container {
  position: absolute;
  top: 18%;
  height: 15vh;
  left: 0;

  width: fit-content;
  z-index: 2;
}
.div-carrot {
  height: 50%;
  width: fit-content;
  position: absolute;
  top: 25%;
  left: 10%;
}
.div-carrot .div-carrot-score {
  position: absolute;
  top: 35%;
  color: white;
  font-size: 100%;
  left: 20%;
  text-align: center;
  font-family: "Sour Gummy", serif !important;
  font-weight: 600;
}

.rabbit-container-cheer {
  margin-top: -5%;
  height: 16.5vh;
}
.rabbit-container-jump {
  margin-top: -2%;
}

.rabbit-container img {
  max-height: 100%;
}
.rabbit-container-track-1 {
  top: -16%;
}
.rabbit-container-track-2 {
  top: 4%;
}

.rabbit-container-track-3 {
  top: 24%;
}

.rabbit-container-track-4 {
  top: 44%;
}
.rabbit-container-track-5 {
  top: 64%;
}

.div-bot-setting {
}
.div-bot-setting h4 {
  text-align: left;
}
.div-bot-setting .div-form {
  margin: 0.4em;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1em;
}
.div-bot-setting .div-form * {
  margin: 0;
  text-align: left;
}
.div-bot-setting .div-form input,
.div-bot-setting .div-form select {
  width: 80%;
  margin: 0 !important;
}

.lose-buttons {
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .leaderboard-modal-container {
    width: 80vw;
  }
  .feedback-bg {
    max-width: 80vw;
    padding: 5vh 5vw;
  }
  .div-name-input {
    max-width: 80vw !important;
    min-width: unset !important;
  }
  .passwords-container {
    width: 100vw !important;
    max-width: 80vw;
  }
  .wordle-log-in-container {
    width: 80vw;
    left: 5vw;
  }

  .challenge-form-container {
    max-width: none;
    width: 80vw;
    left: 5vw;
  }

  .challenge-name {
    font-size: 1em;

    display: flex;
    justify-content: space-between;
  }
  .challenge-name h3 {
    font-size: 1em;
  }
  .challenge-name .question {
    font-size: 0.8em;
  }
  .challenge-body {
    font-size: 0.8em;
  }
  .below-wordle-message {
    margin-top: 5em;
  }
  .div-intro-container {
    width: 90vw;
  }
  .bunny-race-container {
    width: 90%;
  }
  .game-win-buttons {
    width: 85vw;
  }
  .game-win-buttons button {
    padding: 0.2em;
  }
  .div-rank {
    left: 70vw;
  }
}
