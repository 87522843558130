.exam-container {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
}

.exam-header {
  display: flex;
  justify-content: center;
  gap: 1em;
  width: 90vw;
  border-bottom: solid 1px #ccc;
  margin: 0 auto;
}
.exam-header h3 {
  margin: 0.5em 0;
}
.exam-body {
  display: flex;
  flex-direction: column;
  justify-content: baseline;

  height: 100%;
  flex-grow: 1;
}
.instructions-submit {
  display: flex;
  justify-content: space-between;
  width: 90vw;
  margin: 0.4em auto;
}
.div-instructions {
  font-size: 1.2em;
}
.match-div-options {
  display: flex;
  gap: 1em;
  width: 80vw;
  margin: 1em auto;
  padding: 2vh 2.5vw;
  flex-wrap: wrap;
  border: solid 1px #ccc;
  background-color: #efefef22;
  height: 5em;
  overflow-y: scroll;
}
.match-div-options .match-option {
  padding: 4px 8px;
  border: solid 1px #555;
  cursor: pointer;
  border-radius: 8px;
  cursor: pointer;
  height: fit-content;
}
.option-selected {
  background-color: #3981b7;
  color: white;
}
.div-match-questions {
  height: 100%;
  flex-grow: 1;
  flex-basis: 1;
  text-align: left;
  width: 90vw;
  margin: 0 auto;
  overflow-y: scroll;
  font-weight: 400;
}
.div-match-questions .match-question {
  line-height: 2.4em;
  font-size: 1.2em;
  display: inline;
}
.match-question-parts {
  display: inline;
  margin-left: 0.5em;
}
.match-question-parts * {
  display: inline;
}
.answer-box-container {
  border: solid 1px #ccc;
  padding: 1px 1em;
  border-radius: 2px;
  cursor: pointer;
}
.answer-box-container-empty {
  color: white;
}
.div-check-cross {
  padding: 0 0.5em;
}
