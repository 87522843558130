.prices-container {
  background-color: white;
  width: 100%;
  margin: auto;
  padding: 5vh 0;
}

.prices-container h1 {
  color: #757575;
  font-weight: 400;
  margin: 1em 0;
}

.price-box-grid {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
}
.price-box-container {
  width: 220px;
  margin: 1em auto;
  border-radius: 20px;
  position: relative;
  padding-top: 50px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hours-container {
  position: absolute;
  top: -40px;

  width: 100%;
  display: flex;
  justify-content: center;
}
.hours-container h3 {
  height: 40px;
  width: fit-content;

  padding: 0 2em;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.price-container {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  font-size: 1.4em;
}
.price-container h3 {
  height: fit-content;
  margin: auto;
  line-height: 0;
}

.price-box-container ul {
  text-align: left;
  font-size: 0.8em;
  color: #757575;
}
.price-box-container ul li {
  line-height: 2em;
}
.div-link {
  width: fit-content;
  margin: auto;
  padding: 8px;
  border-radius: 4px;
}

.div-link a {
  text-decoration: none;
  color: white;
  font-weight: 600;
  display: flex;
  gap: 0.2em;
}
.div-link a * {
  margin: auto;
}
.quote-button-container {
  margin-bottom: 3em;
}
.quote-button-container h3 {
  color: #757575;
  font-weight: 400;

  font-size: 2em;
  margin-bottom: 1em;
}
.quote-button-container a {
  background-color: #1d4856;
  color: white;
  text-decoration: none;
  font-size: 1em;
  margin: 4em auto;
  padding: 0.5em 1em;
  border-radius: 4px;
}
.wordle-button-container {
  width: 90vw;
}

.wordle-button-container h3 {
  color: rgb(6, 147, 227);
  font-weight: 300;
}
.wordle-button-container a {
  background-color: rgb(6, 147, 227);
  cursor: pointer;
}

@media (max-width: 767px) {
  .popular-courses-container {
    padding: 0 !important;
    width: 100vw !important;
  }
  .price-box-grid {
    width: 92% !important;
    grid-template-columns: 1fr 1fr;
    padding: 2%;
    margin: 2em auto !important;
    overflow-x: scroll;
  }
  .wordle-button-container {
    width: 100vw;
    h3 {
      font-size: 1.6em;
    }
    padding: 2em 0;
  }
  .price-box-container {
    width: 150px;
    font-size: 0.8em;
    padding-top: 20px;
    margin-top: 30px;
  }
  .hours-container {
    margin-top: 5px;
  }
  .hours-container h3 {
    height: 30px;
    padding: 0 10px;
    border-radius: 12px;
  }
  .price-container {
    height: 30px;
  }
}
